import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {SelectControlled} from "../components/InputControlled";
import LeaderboardData from "../json/leaderboard-data.json";
import ArticlesPage from "../gamearticles/ArticlesPage";
import {analyticsPageView} from "../analytics/AnalyticsService";

import { isAndroid, isIOS } from "react-device-detect";

export interface Season {
    sync: Player[],
    syncChaos: Player[],
    async: Player[],
    asyncChaos: Player[],
}
export interface Player {
    name: string,
    p: number,
}

export default function LandingPage(props: { suppressRedirect?: boolean }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get('referral');

    const particles = Array.from({ length: 100 }).map((_, idx) => {
        const style = {
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            width: '10px',
            height: '10px',
            backgroundColor: `hsl(${Math.random() * 360}, 100%, 50%)`,
        };

        return <div key={idx} className="particle rect-particle" style={style} />;
    });

    const navigate = useNavigate();
    const playStoreLink = "https://play.google.com/store/apps/details?id=at.FairytaleSoftware.FairytaleFables";
    const appStoreLink = "https://apps.apple.com/app/fairytale-fables/id6499146025";

    useEffect(() => {
        if(window.location.search.includes("redirect=app")) {
            if(isAndroid) {
                window.location.href = playStoreLink;
            } else if(isIOS) {
                window.location.href = appStoreLink;
            }
        }
    }, [navigate, queryParams]);

    return <div className="position-relative">
        <div style={{background: "url(/assets/img/ex-bg.webp) no-repeat", backgroundSize: "cover", minHeight: "60vh"}}>
            <section id="hero" className="d-flex align-items-center pb-1">
                <div className="container m-auto mt-5 d-md-flex">
                    <div>
                        <img className="mw-100 d-inline-block" src="/assets/img/title-new.webp" alt="FairyTale Fables"/>
                    </div>
                    {/* <div className="particle-container">
                        <img className="mw-100 d-inline-block" src="/assets/img/title-new.webp" alt="FairyTale Fables"/>
                        {particles}
                    </div> */}

                    {/* register button*/}
                    <div className="text-center mt-3 d-md-inline-block px-3 px-md-5">
                        <a target="_blank" rel="noreferrer" className="nav-link fs-5 mb-3 rounded px-2 py-1 bg-black"
                           href="https://store.steampowered.com/app/2639420/Fairytale_Fables/" onClick={() => analyticsPageView('store-steam?' + queryParams)}>
                            <img className="" width="190" src="/assets/img/steam.webp" alt="Steam"/>
                        </a>
                        <a target="_blank" rel="noreferrer" className="nav-link fs-5 mb-3"
                           href={playStoreLink} onClick={() => analyticsPageView('store-android?' + queryParams)}>
                            <img width="200" src="/assets/img/google-play-badge.webp" alt="Play on Android"/>
                        </a>
                        <a target="_blank" rel="noreferrer" className="nav-link fs-5 mb-3"
                           href={appStoreLink} onClick={() => analyticsPageView('store-ios?' + queryParams)}>
                            <img width="200" src="/assets/img/app-store-badge.webp" alt="Play on iOS"/>
                        </a>
                        <a target="_blank" rel="noreferrer" className="nav-link fs-5 mb-3 rounded px-0 py-2"
                           style={{backgroundColor: "#5865F2"}} href="https://discord.gg/FbvDjSbZ7c" onClick={() => analyticsPageView('discord?' + queryParams)}>
                            <img width="180" src="/assets/img/discord.webp" alt="Join us on Discord"/>
                        </a>
                    </div>
                </div>
            </section>

            {false && <section className="d-flex align-items-center py-2 text-center">
                <div className="container bg-fancy card d-inline-block" style={{maxWidth: "800px"}}>
                    <h4 className="mb-2">It would be a great help if you</h4>
                    <a target="_blank" rel="noreferrer"
                       className="fs-5 mb-3 rounded px-0 py-2 m-auto"
                       href="https://www.kickstarter.com/projects/fairytalesoftware/art-for-fairytale-fables">
                        <h3 className="mb-0">Wishlist our Kickstarter!</h3>
                        <img width="500" src="/assets/img/title-small.webp" alt=""/>
                    </a>
                </div>
            </section>}
            {referralCode !== null && <section className="d-flex align-items-center py-2">
                <div className="container bg-fancy card" style={{maxWidth: "800px"}}>
                    <h4 className="text-center mb-1">Use the code</h4>
                    <h1 className="text-center link-style">{referralCode}</h1>
                    <h4 className="text-center">in the in-game shop<br/>to get a free 10 day battlepass and 4000
                        crystals!</h4>
                </div>
            </section>}
        </div>

        {/*
        <section className="d-flex align-items-center">
            <div className="container bg-fancy card">
                <h2 className="text-center">Events</h2>
                <UpcomingEvents/>
            </div>
        </section>
        */}

        <section className="d-flex align-items-center">
            <div className="container bg-fancy card">
                <h2 className="text-center">Articles</h2>
                <h5>Read the latest articles <Link className="d-inline" to="/articles">here</Link>.</h5>
                <hr/>
                <ArticlesPage maxArticles={3}/>
            </div>
        </section>

        <section className="d-flex align-items-center">
            <div className="container bg-fancy card">
                <h2 className="text-center">Hall of Fame</h2>
                <HallOfFame/>
            </div>
        </section>
    </div>;
}

function UpcomingEvents(props: {}) {
    return <>
        <div>
            <h3>Monthly Tournament</h3>
            <div>
                The monthly tournament will be held the last weekend every month.
            </div>
        </div>
        <div>
            <h3>Monthly 1v1 Tournament</h3>
            <div>
                The monthly 1v1 tournament will be held the second weekend every month.
            </div>
        </div>
    </>;
}

function HallOfFame(props: {}) {
    const [lastSeason, lastSeasonSet] = useState("2024-12");
    // ^ +([^\t\n]+)\t(\d+)$
    // ([a-zA-Z0-9!\.\-\_]+)\t(\d+)
    // {name: "$1", p: $2},
    const topPlayers: Record<string, Season> = LeaderboardData;
    delete topPlayers['base'];

    const indices: number[] = [];
    for(let i=0; i<100; ++i) indices.push(i);

    const keys: string[] = [];
    for (let key in topPlayers) {
        if(topPlayers[key].sync.length===0) continue;
        keys.push(key);
    }
    keys.sort();
    keys.reverse();

    const season = topPlayers[lastSeason];

    return <div className="overflow-auto">
        <h4 className="text-center">Ranking of {lastSeason}</h4>
        <SelectControlled className="form-control" value={lastSeason} handleChange={v => lastSeasonSet(v)}>
            {keys.map(k => <option key={k} value={k}>
                {k}
            </option>)}
        </SelectControlled>

        <table className="table table-dark table-striped table-hover mw-100 mt-3">
            <thead>
            <tr>
                <td className="fame-div">Ranking</td>
                <td className="fame fame-div" colSpan={2}>Sync</td>
                <td className="fame fame-div" colSpan={2}>Sync Chaos</td>
                <td className="fame fame-div" colSpan={2}>Async</td>
                <td className="fame fame-div" colSpan={2}>Async Chaos</td>
            </tr>
            </thead>
            <tbody>
                {indices.map(index => <tr key={index}>
                    <td className="fame-div">{index + 1}.</td>

                    <td className="fame">{season.sync[index].name}</td>
                    <td className="fame-div">{season.sync[index].p}</td>

                    <td className="fame">{season.syncChaos[index].name}</td>
                    <td className="fame-div">{season.syncChaos[index].p}</td>

                    <td className="fame">{season.async[index].name}</td>
                    <td className="fame-div">{season.async[index].p}</td>

                    <td className="fame">{season.asyncChaos[index].name}</td>
                    <td className="fame-div">{season.asyncChaos[index].p}</td>
                </tr>)}
            </tbody>
        </table>
    </div>
}